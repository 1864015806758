import React, { Component } from 'react'

class Product extends Component {
    static displayName = 'Product';

    render() {
        return (
            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div class="flex-shrink-0">
                    {// eslint-disable-next-line
                    }<a href={ this.props.linkUrl } target="_blank"><img class="h-48 w-full object-cover" src={ this.props.imageUrl } alt="" /></a>
                </div>
                <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div class="flex-1">
                        <p class="text-sm font-medium text-green-600">
                            { this.props.category }
                        </p>
                        {// eslint-disable-next-line
                        }<a href={ this.props.linkUrl } target="_blank" class="block mt-2">
                            <p class="text-xl font-semibold text-gray-900">
                                { this.props.title }
                            </p>
                            <p class="mt-3 text-base text-gray-500">
                                { this.props.description }
                            </p>
                        </a>
                        {// eslint-disable-next-line
                        }<a href={ this.props.linkUrl } target="_blank" class="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-full shadow-sm text-green-700 bg-green-200 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-300">
                            Purchase on <img src="/img/Amazon-32.png" className="ml-2" alt="Amazon" />
                        </a>
                    </div>
                    <div class="mt-6 flex">
                        <div class="">
                            <div class="flex space-x-1 text-sm text-gray-500">
                                { 
                                /*
                                    <span className="text-green-700">
                                        { this.props.price }
                                    </span>
                                    <span aria-hidden="true">
                                        · 
                                    </span>
                                */
                                }
                                &nbsp;Last Updated:&nbsp;
                                <time>
                                    { this.props.added }
                                </time>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Product;