import React, { Component } from 'react'
import { Transition } from '@headlessui/react'
import Hero from './Hero'
import './css/hero.css'
import { Link } from "react-router-dom";

class Header extends Component {
    static displayName = 'Header';

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }

        this.setIsOpen = this.setIsOpen.bind(this);
    }

    setIsOpen(isOpen) {
        this.setState({isOpen: isOpen});
    }

    render() {
        var heroWrapper;
        var heroClass;
        if (this.props.showHero) {
            heroWrapper = "hero-height w-full bg-fixed bg-center bg-cover bg-opacity-50 border-b-4 border-green-400"
            heroClass = "relative pb-16 sm:pb-24 lg:pb-32";
        } else {
            heroWrapper = "no-hero-height w-full bg-fixed bg-center bg-cover bg-opacity-50 border-b-4 border-green-400"
            heroClass = "relative";
        }

        return (
            <header className={ heroWrapper } style={{ backgroundImage: "url('/img/hero-dog.jpg')" }}>
                <div className="h-full relative overflow-hidden">
                    <div className={heroClass}>
                        <nav className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
                            <div className="flex items-top flex-1">
                                <div className="flex items-center justify-between w-full md:w-auto">
                                    <Link to="/" className="pt-6">
                                        <span className="sr-only">Nerd Puppy Animal Training</span>
                                        <img className="w-24" src="/img/logoWhite.png" alt="" />
                                    </Link>
                                    <div className="pt-6 -mr-2 flex items-center md:hidden">
                                        <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-400" id="main-menu" aria-haspopup="true" onClick={() => this.setIsOpen(!this.state.isOpen)}>
                                            <span className="sr-only">Open main menu</span>
                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="pt-12 hidden md:block md:mx-10 md:space-x-10">
                                    <a href="/#pricing" className="font-medium text-white hover:text-gray-300">Services and Pricing</a>
                                    <a href="/#testimonial" className="font-medium text-white hover:text-gray-300">Graduate Testimonials</a>
                                    <a href="/#get-started" className="font-medium text-white hover:text-gray-300">Get Started Today</a>
                                    <Link to="faq" className="font-medium text-white hover:text-gray-300">F.A.Q.</Link>
                                    <a href="/#about-us" className="font-medium text-white hover:text-gray-300">Learn About Us</a>
                                    <Link to="products" className="font-medium text-white hover:text-gray-300">Products</Link>
                                </div>
                                <div className="hidden md:block bg-green-400 w-16 p-4 flex flex-col justify-between">
                                    <div><a href="https://www.facebook.com/Nerd-Puppy-112011386836324/"><svg className="hover:text-blue-600 text-white my-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 192v-38.1c0-17.2 3.8-25.9 30.5-25.9H352V64h-55.9c-68.5 0-91.1 31.4-91.1 85.3V192h-45v64h45v192h83V256h56.4l7.6-64h-64z"></path></svg></a></div>
                                    <div><a href="https://www.instagram.com/nerdpuppyaz/?hl=en"><svg className="hover:text-indigo-600 text-white my-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M338,54.63H174c-62.72,0-114,51.32-114,114V343.33c0,62.72,51.32,114,114,114H338c62.72,0,114-51.32,114-114V168.67C452,105.94,400.68,54.63,338,54.63Zm83.91,288.71A84.24,84.24,0,0,1,338,427.24H174a84.24,84.24,0,0,1-83.91-83.91V168.67A84.24,84.24,0,0,1,174,84.76H338a84.24,84.24,0,0,1,83.91,83.91Z"></path><path d="M255.77,148.18A107.94,107.94,0,1,0,363.71,256.13,107.94,107.94,0,0,0,255.77,148.18Zm-.52,178.71a70.68,70.68,0,1,1,70.68-70.68A70.68,70.68,0,0,1,255.25,326.9Z"></path><circle cx="365.44" cy="146.5" r="25.56"></circle></svg></a></div>
                                </div>
                            </div>
                        </nav>

                        <Transition
                            show={this.state.isOpen}
                            enter="duration-150 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95" >
                            <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10">
                                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="px-5 pt-4 flex items-center justify-between">
                                        <Link to="/">
                                            <img className="h-8 w-auto" src="/img/logoCircle.png" alt="" />
                                        </Link>
                                        <div className="-mr-2">
                                            <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-400" onClick={() => this.setIsOpen(!this.state.isOpen)}>
                                                <span className="sr-only">Close main menu</span>
                                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                                        <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                                            <a href="/#pricing" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">Services and Pricing</a>

                                            <a href="/#testimonial" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">Graduate Testimonials</a>

                                            <a href="/#get-started" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">Get Started Today</a>

                                            <Link to="faq" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">F.A.Q.</Link>

                                            <a href="/#about-us" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">Learn About Us</a>

                                            <Link to="products" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">Products</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>

                        { (this.props.showHero) && (<Hero />) }

                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
