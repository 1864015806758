import React, { Component } from 'react'

class Footer extends Component {
    static displayName = 'Footer';

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div>
                <div className="relative h-64 border-t-4 border-green-500">
                    {/*
                    <div className="flex absolute h-64 overflow-hidden">
                        <img className="inline-block" src="/img/promo/dog1.jpg" alt="Good Boy" />
                        <img className="inline-block" src="/img/promo/dog2.jpg" alt="Agility Dog" />
                        <img className="inline-block" src="/img/promo/dog3.jpg" alt="Wind Swept Dog" />
                        <img className="inline-block" src="/img/promo/dog4.jpg" alt="Silly Dog" />
                        <img className="inline-block" src="/img/promo/dog5.jpg" alt="Patient Dog" />
                        <img className="inline-block" src="/img/promo/dog6.jpg" alt="Happy Dog" />
                        <img className="inline-block" src="/img/promo/dog7.jpg" alt="Adventure Dog" />
                        <img className="inline-block" src="/img/promo/dog8.jpg" alt="Dog Found a Stick" />
                    </div>
                    */}
                    <div className="flex absolute h-64 overflow-hidden">
                        <img className="inline-block" src="/img/graduates/originals/annie.jpg" alt="Annie the Dog" />
                        <img className="inline-block" src="/img/graduates/originals/arya.jpg" alt="Arya the Dog" />
                        <img className="inline-block" src="/img/graduates/originals/beardsley.jpg" alt="Beardsley the Dog" />
                        <img className="inline-block" src="/img/graduates/originals/bella.jpg" alt="Bella the Dog" />
                        <img className="inline-block" src="/img/graduates/originals/finnigan.jpg" alt="Finnigan the Dog" />
                        <img className="inline-block" src="/img/graduates/originals/jax.jpg" alt="Jax the Dog" />
                        <img className="inline-block" src="/img/graduates/originals/tucker.jpg" alt="Tucker the Dog" />
                        <img className="inline-block" src="/img/graduates/originals/winston.jpg" alt="Winston the Dog" />
                    </div>
                </div>
                <footer className="bg-gray-800" aria-labelledby="footerHeading">
                <h2 id="footerHeading" className="sr-only">Footer</h2>
                <div className="max-w-7xl mx-auto pt-6 pb-12 px-4 sm:px-6 lg:pb-16 lg:pt-8 lg:px-8">
                    <div className="flex w-full justify-center space-x-4">
                        <img className="w-12 inline-block" src="/img/visa.png" alt="Visa" />
                        <img className="w-12 inline-block" src="/img/mastercard.png" alt="Mastercard" />
                        <img className="w-12 inline-block" src="/img/amex.png" alt="American Express" />
                        <img className="w-12 inline-block" src="/img/discover.png" alt="Discover" />
                    </div> 
                    <div className="pb-4 pt-3 text-sm text-gray-500 text-center">We currently accept cash, check, Apple Pay, Google Pay and major credit cards.</div>
                    <div className="border-t border-gray-500 pt-4 lg:flex lg:items-center lg:justify-between xl:mt-0">
                        <div>
                            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                            Join Our Email List
                            </h3>
                            <p className="mt-2 text-base text-gray-300">
                            We'll let you know when we start puppy group classes for training and socialization.
                            </p>
                        </div>
                    <form className="mt-4 sm:flex sm:max-w-md lg:mt-0" action="https://nerdpuppy.us4.list-manage.com/subscribe/post?u=2036e4a4aa88560be37704f1e&amp;id=193a962132" method="post">
                        <label htmlFor="mce-EMAIL" className="sr-only">Email address</label>
                        <input id="mce-EMAIL" type="email" name="EMAIL" autoComplete="email" required className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400 sm:max-w-xs" placeholder="Enter your email" />
                        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                        <button type="submit" className="w-full bg-green-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-green-500">
                            Subscribe
                        </button>
                        </div>
                    </form>
                    </div>
                    <div className="mt-4 pt-8 md:flex md:items-center md:justify-between">
                    <div className="flex space-x-6 md:order-2">
                        <a href="https://www.facebook.com/Nerd-Puppy-112011386836324/" className="text-gray-400 hover:text-gray-300">
                            <span className="sr-only">Facebook</span>
                            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                            </svg>
                        </a>

                        <a href="https://www.instagram.com/nerdpuppyaz/?hl=en" className="text-gray-400 hover:text-gray-300">
                            <span className="sr-only">Instagram</span>
                            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                            </svg>
                        </a>
                    </div>
                    <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                        &copy; 2021 Nerd Puppy LLC. All rights reserved.
                    </p>
                    </div>
                </div>
                </footer>
            </div>
        );
    }
}

export default Footer;