import React, { Component } from 'react'
import Graduate from './components/Graduate';
import ContactForm from './components/ContactForm';

class ContactWizard extends Component {
    static displayName = 'ContactWizard';

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div id="get-started" className="py-16 bg-gray-50 overflow-hidden lg:py-24">
                <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                    <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
                        <defs>
                            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
                    </svg>

                    <div className="relative">
                        <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            A better way to train
                        </h2>
                        <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                            With training started early you will have better experiences in public places, low stress vet visits, enjoyable outdoor activities and an ideal at-home companion.
                        </p>
                    </div>

                    <div className="flex flex-wrap lg:flex-nowrap relative mt-12 lg:mt-24 lg:items-center lg:space-x-16">
                        <div className="lg:w-1/2 relative">
                            <h3 className="text-center lg:text-left text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                Every pup is unique
                            </h3>
                            <p className="text-center lg:text-left mt-3 text-lg text-gray-500">
                                In our first session, we'll get started right away by setting some goals and doing some initial training to get a better understanding of your pup's strengths and weaknesses.
                            </p>

                            <dl className="mt-10 space-y-10">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                        </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            Learn How to Communicate
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            Frustrated dog parents will often do more harm than good by over-reacting. Learn how to effectively communicate with your pup and set expectations while building trust.
                                        </dd>
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            Positive Reinforcement Training
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            As we have our sessions together, you'll understand the benefits of positive reinforcement training. Building long-lasting behaviors and obedience by reward rather than punishment has been scientifically proven to be superior to the dominance methods of training.
                                        </dd>
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            Affordable Rates
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            Our pricing is highly competitive and with professional trainers you'll get quality training with years of experience and knowledge at your fingertips.
                                        </dd>
                                    </div>
                                </div>
                            </dl>
                        </div>

                        <div className="flex-grow relative mt-16 lg:mt-0" aria-hidden="true">
                            <ContactForm />
                        </div>
                    </div>

                    <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
                        <defs>
                            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                    </svg>


                    <div id="testimonial" className="my-12 lg:my-24 mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                        <div>
                            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                            Recent Graduates
                            </p>
                            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                                Hear what the proud parents of our graduates have to say
                            </p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <Graduate
                            dogname="Kali &amp; Evie"
                            breed="Cockapoo"
                            image="/img/graduates/kali.jpg"
                            testimonial="It became so difficult to walk my dogs on the leash because they would both pull, bark, jump, etc. It got to the point where I was having to take them out separately and I was honestly just embarrassed. I called Kristie and was impressed with her experience and her mission to train dogs using positive reinforcement only! She listened to my goals, my wants, and ultimately trained me and the dogs. I told her that I really wanted to have well behaved dogs that I could bring with me to the local farmers market. After about 2 months we were able to do it!" />
                        <Graduate
                            dogname="Beardsley &amp; Benji"
                            breed="Shepard Mix"
                            image="/img/graduates/beardsley.jpg"
                            testimonial="We are so happy we chose Nerd Puppy to have our two dogs trained- they are quite a handful, both being under 2 years old, and we learned so many great tricks and commands to help them behave better in public and at home. At the beginning of our training I was terrified to take them out on walks because of their behavior, and now I can take them to busy parks!" />
                        <Graduate
                            dogname="Bella"
                            breed="Chow Mix"
                            image="/img/graduates/bella-2.jpg"
                            testimonial="Nerd puppy training is a life saver!! My dog was very reactive towards anything while on a leash and hardly could pay attention. After a few sessions with Kristie, it's like a whole new dog. We are able to actually go on walks now and enjoy our time together instead of it being a struggle." />
                        <Graduate
                            dogname="Jax"
                            breed="Pitbull"
                            image="/img/graduates/jax.jpg"
                            testimonial="Kristie was great with Jax! she broke big tasks into little steps and accomplished more than I ever thought possible. Plus she was affordable and convenient. she came to us! 100% recommend. She knows her stuff." />
                        <Graduate
                            dogname="Annie"
                            breed="Shih Tzu"
                            image="/img/graduates/annie.jpg"
                            testimonial="This is an excellent training!  Kristie was very positive and cute with our puppy.  Our puppy is so much better behaved and happy and we only had 5 1 hour classes.  I would highly recommend Kristie at Nerd Puppy!!" />
                        <Graduate
                            dogname="Loa"
                            breed="Rottweiler Mix"
                            image="/img/graduates/loa.jpg"
                            testimonial="Our pup Loa is being trained by Kristie and we absolutely love her! She was able to come over to our home and help us get to know Loa better. Kristie is patient and you can definitely see her love for dogs!" />
                        <Graduate
                            dogname="Kodi"
                            breed="Cattle Dog Mix"
                            image="/img/graduates/kodi.jpg"
                            testimonial="We highly recommend Kristie from Nerd Puppy! We did a 5-lesson package with her for our new puppy Kodi. Kristie uses positive motivation and lots of love. In the time that we worked with her, Kodi no longer does puppy biting, knows all his basic commands, and walks by our side. She also guided us on getting him to greet other people and dogs properly meaning sitting with no jumping. At 7 months old you can see him wanting to just go wild, but he is able to maintain his composure and keep his bottom on the ground. She also taught us ways to makes chewing less destructive and he no longer chews up my sprinkler driplines (thankful for this one).  As we walk past the sprinkler driplines, I give him the command and he ignores them and now he pays them no mind at all. My favorite is his recall command. When he hears the recall, he comes running no matter where he is or what he is doing. Overall, Kristie is fabulous, extremely knowledgeable and is always willing to answer our questions. Kodi loves when she shows up for the sessions and he will be off leash soon. The most important thing she does during her sessions is teach us how to train Kodi effectively with kindness and no harsh treatment. Kristie’s value is much higher than what we paid and she had the best pricing of all the companies we called while looking for a trainer." />
                        
                    </div>
                    <div className="italic text-gray-800 mt-4 text-sm text-right">Read More Reviews on <a className="text-green-400 hover:text-green-600 underline cursor-pointer" href="https://g.page/NerdPuppy">Google</a> or <a className="text-green-400 hover:text-green-600 underline cursor-pointer" href="https://www.facebook.com/Nerd-Puppy-112011386836324/reviews/">Facebook</a><br /><a className="text-green-400 hover:text-green-600 underline cursor-pointer" href="https://g.page/NerdPuppy/review?rc">Leave a Review</a></div>
                </div>
            </div>
        );
    }
}

export default ContactWizard;