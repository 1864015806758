import React, { Component } from 'react'
import './css/video.css'

class Hero extends Component {
    static displayName = 'Hero';

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <section className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                    <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                        <h1>
                            <span className="block text-sm font-semibold uppercase tracking-wide text-gray-200 opacity-90 sm:text-base lg:text-sm xl:text-base">Positive Reinforcement Animal Training</span>
                            <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                                <span className="block text-gray-100">Looking for Experience?</span>
                                <span className="block text-green-400">Positive and Effective</span>
                            </span>
                        </h1>
                        <p className="mt-3 text-base text-gray-100 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                            Shock collars and prong collars are a thing of the past. Learning to communicate effectively with scientifically backed training methods are proven to improve your pup's behaviour and foster a healthly, trusting relationship.
                        </p>
                    </div>
                    <div className="lg:col-span-6 embed-container my-6 lg:my-0">
                        <iframe src='https://player.vimeo.com/video/553605625' title="Welcome Video" frameBorder='0' webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
                    </div>
                </div>
            </section>
        );
    }
}

export default Hero;