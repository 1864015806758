import React, { Component } from 'react'

class AboutUs extends Component {
    static displayName = 'AboutUs';

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div className="mb-12 lg:mb-36" id="about-us">
                <div className="relative my-12 lg:my-24">
                    <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        About Us
                    </h2>
                    <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                        A little bit about how Nerd Puppy started
                    </p>
                </div>
                <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                        <img className="object-cover shadow-lg rounded-lg" src="/img/Kristie.jpg" alt="" />
                    </div>
                    <div className="sm:col-span-2">
                        <div className="space-y-4">
                            <div className="text-lg leading-6 font-medium space-y-1">
                                <h3>Kristie</h3>
                                <p className="text-green-500">Dog Trainer and Owner</p>
                            </div>
                            <div className="text-lg">
                                <p className="text-gray-500">Hi, my name is Kristie and I have been working with animals professionally for 10 years. I am originally from Nevada but have traveled all over the country training exotic animals including many species of sea lions, dolphins, whales, otters and penguins. I have presented at the international IMATA Conference in Portugal where professionals all over the world meet to discuss the latest methods of behavioral modification and psychology in all animals. After transitioning out of the Marine Mammal field and moving to Arizona, I picked up the dog whistle and started Nerd Puppy. What I enjoy most about training is seeing animals succeed and get excited when they get to learn something new. I look forward to teaching you and your pet! </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutUs;