import React, { Component } from 'react'

class Dropdown extends Component {
    static displayName = 'Dropdown';

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        }
    }

    render() {
        return (
            <div className="pt-6">
                <dt className="text-lg">
                    <button className="text-left w-full flex justify-between items-start text-gray-400" onClick={() => {this.setState({isOpen: !this.state.isOpen})}}>
                        <span className="font-medium text-gray-900">
                            {this.props.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                            <svg className={`${this.state.isOpen ? '-rotate-180' : 'rotate-0'} h-6 w-6 transform`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                            </svg>
                        </span>
                    </button>
                </dt>
                <dd className={`${this.state.isOpen ? 'block' : 'hidden'} mt-2 pr-12`}>
                    <p className="text-base text-gray-500">
                        {this.props.answer}
                    </p>
                </dd>
            </div>
        );
    }
    
}

export default Dropdown;