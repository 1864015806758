import React, { Component } from 'react'

class Graduate extends Component {
    static displayName = 'Graduate';

    constructor(props) {
        super(props);

        this.state = {
            shortTestimonial: '',
            shown: false
        }
    }

    componentDidMount() {
        if (this.props.testimonial.length > 300) {
            this.setState({
                shortTestimonial: this.props.testimonial.substring(0, 300) + '...'
            });
        } else {
            this.setState({
                shortTestimonial: false,
                shown: false
            });
        }
    }

    render() {
        return (
            <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={ this.props.image } alt="" />
                    </div>
                    <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900">
                        { this.props.dogname }
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                        { this.props.breed }
                        </p>
                    </div>
                    <span className="rounded-lg inline-flex p-3 bg-blue-50 text-blue-700 ring-4 ring-white">
                        <svg className="h-6 w-6" x-description="Heroicon name: outline/academic-cap" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z"></path>
                        <path fill="#fff" d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
                        </svg>
                    </span>
                </div>
                <div className="mt-2 text-sm text-gray-600 italic">
                    {
                        (this.state.shortTestimonial && !this.state.shown) ? (
                            <div>
                                <span>"{ this.state.shortTestimonial }"</span>
                                <button className="text-green-600 ml-2" onClick={() => {this.setState({shown: !this.state.shown})}}>read more</button>
                            </div>
                        ) : (
                            <div>
                                <span>"{ this.props.testimonial }"</span>
                                { (this.state.shortTestimonial) && <button className="text-green-600 ml-2" onClick={() => {this.setState({shown: !this.state.shown})}}>read less</button>}
                            </div>
                        )
                    }
                    
                </div>
            </div>
        );
}

}

export default Graduate;