import React, { Component } from 'react'
import Product from './components/Product'
import Header from './Header'
import Footer from './Footer'

class Products extends Component {
    static displayName = 'Products';

    constructor(props) {
        super(props);

        this.state = {
            active: 'All',
            products: [
                {
                    asin: "B00HS5FOVA",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00HS5FOVA&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    linkUrl: "https://amzn.to/3ukY1bX",
                    category: "Training",
                    title: "Kurgo 6 in 1 Quantum Leash",
                    description: "There are many leashes out there. This one is excellent because it offers hands free training that keeps your pup safe but allows you to focus on rewarding good behavior.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021" ,
                    price: "$19.96",
                },
                {
                    asin: "B0002AR17S",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0002AR17S&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Toys",
                    linkUrl: "https://amzn.to/347UaEj",
                    title: "KONG - Puppy Toy Natural Teething Rubber",
                    description: "Quality soft rubber safe for puppy teething.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$11.99"
                },
                {
                    asin: "B082FQ1M6B",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B082FQ1M6B&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Apparal",
                    linkUrl: "https://amzn.to/3vekubQ",
                    title: "RUFFWEAR, Front Range Dog Harness",
                    description: "A great beginning harness. Every dog is different so a harness is not a one size fits all, but a nice front and back clip is great for training.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$39.95"
                },
                {
                    asin: "B07SZNNCQK",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07SZNNCQK&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Training",
                    linkUrl: "https://amzn.to/348IAZK",
                    title: "Dog/Puppy Obedience Recall 30ft Training Leash",
                    description: "A must have for off leash training, recall training, and being able to safely exercise your pup in a public park.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$9.94"
                },
                {
                    asin: "B086L77P6B",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B086L77P6B&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Toys",
                    linkUrl: "https://amzn.to/3udfnXS",
                    title: "Pole Toy for Dogs",
                    description: "Fun toy to allow your dog to chase and play with less owner exertion. Great for burning some energy before training time.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$28.99"
                },
                {
                    asin: "B07QYZ2YPH",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07QYZ2YPH&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Toys",
                    linkUrl: "https://amzn.to/3hN9Nc8",
                    title: "Snuffle Mat Pet Dog Feeding Mat",
                    description: "This mat helps stimulate your pup's mind as they forage and explore. Great for all breeds, please supervise your pup at all times with this toy.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$34.19"
                },
                {
                    asin: "B07PT36V4T",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07PT36V4T&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Training",
                    linkUrl: "https://amzn.to/3f8pt8m",
                    title: "Model 211.5 Plastic Dog Whistle Baltic Blue",
                    description: "Recall Whistle (recall training is required to use this item effectively).",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$9.79"
                },
                {
                    asin: "B0893NNH5V",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0893NNH5V&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Training",
                    linkUrl: "https://amzn.to/3ytBHQu",
                    title: "Model 211.5 Plastic Dog Whistle Forest Green",
                    description: "Recall Whistle (recall training is required to use this item effectively).",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$13.97"
                },
                {
                    asin: "B000GQJG2G",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B000GQJG2G&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Toys",
                    linkUrl: "https://amzn.to/3ywVSNv",
                    title: "KONG - Puppy Flyer - Teething Rubber, Flying Disc",
                    description: "Great option for young teething puppies.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$9.99"
                },
                {
                    asin: "B00OZOQN3U",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00OZOQN3U&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Toys",
                    linkUrl: "https://amzn.to/34cBC5C",
                    title: "Pet Zone IQ Treat Ball",
                    description: "Puzzle toy for beginners. Puzzle toys are a great way to stimulate your pup mentally.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$9.90"
                },
                {
                    asin: "B004A7X21I",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B004A7X21I&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Toys",
                    linkUrl: "https://amzn.to/3fCTkEL",
                    title: "Zogoflex Hurley Dog Bone Floatable Chew Toy",
                    description: "Have a strong chewer? This is your toy. West Paw makes some really great products and they offer a free replacement if you pup chews it up. It is not indestructible, no toy is, however even the toughest chewer can still get lots of enjoyment out of this without worrying about chipping a tooth. Comes in multiple sizes.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$16.92"
                },
                {
                    asin: "B01ICVCCJA",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B01ICVCCJA&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Toys",
                    linkUrl: "https://amzn.to/3bLFeQz",
                    title: "StarMark Hot Bob-A-Lot Interactive Dog Toy",
                    description: "Another great puzzle toy for beginners. This toy comes in small and large and can also be adjusted for different sized treats.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$15.62" 
                },
                {
                    asin: "B0002AR15U",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0002AR15U&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Toys",
                    linkUrl: "https://amzn.to/3u7qrWt",
                    title: "KONG - Classic Dog Toy, Durable Natural Rubber",
                    description: "This classic kong can be used as a puzzle toy, stuffed with yummy items, frozen, and more. This toy also comes in many sizes, check the description to ensure you have an appropriate size (bigger is always better if you're unsure).",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$7.99",
                },
                {
                    asin: "B07LB88XF7",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07LB88XF7&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Training",
                    linkUrl: "https://amzn.to/3ua2Zb5",
                    title: "Freeze-Dried Training Treats for Dogs",
                    description: "A favorite of many pups, these make great training treats and are made of simple ingredients.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$15.99",
                },
                {
                    asin: "B007VGE2X2",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B007VGE2X2&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Training",
                    linkUrl: "https://amzn.to/3f924nd",
                    title: "Pet Botanics Training Reward",
                    description: "These small soft treats are great for puppies, they can easily be broken up further for beginning training.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$12.74",
                },
                {
                    asin: "B004HJP17W",
                    imageUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B004HJP17W&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nerdpuppy-20",
                    category: "Training",
                    linkUrl: "https://amzn.to/3wlqJuq",
                    title: "Cloud Star Cloud Star Tricky Trainers Chewy Low Calorie Dog Training Treats",
                    description: "These are a personal favorite. They are a go to for picky pups and young puppies as well. They are easy to break apart and made of quality ingredients without the fillers.",
                    supporter: "Arya",
                    supporterImage: "/img/graduates/originals/arya.jpg",
                    added: "May 21, 2021",
                    price: "$12.44"
                }
            ]
        }
    }

    render() {
        var activeClass="mb-2 mr-2 text-lg inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600";
        var inactiveClass="mb-2 mr-2 text-lg inline-flex items-center px-3 py-1.5 border border-gray-200 text-xs font-medium rounded-full shadow-sm text-gray-500 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200";
        return (
            <div className="App">
                <Header showHero={false} />
                <div className="bg-gray-50">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                        <div className="relative max-w-7xl mx-auto">
                            <div className="text-center">
                                <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                                    Recommended Products
                                </h2>
                                <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                                    A fantastic curated list of my absolute favorite products for raising a smart, healthy pup.
                                </p>
                            </div>
                            
                            <div className="mt-8 text-center md:text-left">
                                <div className="inline-flex mr-2 mb-2 text-gray-600 text-lg">Categories:</div>
                                <button type="button" onClick={() => {this.setState({active: 'All'})}} className={(this.state.active==='All') ? activeClass : inactiveClass}>
                                    All
                                </button>
                                <button type="button" onClick={() => {this.setState({active: 'Toys'})}} className={(this.state.active==='Toys') ? activeClass : inactiveClass}>
                                    Toys
                                </button>
                                <button type="button" onClick={() => {this.setState({active: 'Training'})}} className={(this.state.active==='Training') ? activeClass : inactiveClass}>
                                    Training
                                </button>
                                <button type="button" onClick={() => {this.setState({active: 'Apparal'})}} className={(this.state.active==='Apparal') ? activeClass : inactiveClass}>
                                    Apparal
                                </button>
                            </div>
                            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">

                                {
                                    this.state.products.filter((product, i) => {
                                        if (this.state.active === "All") {
                                            return true;
                                        } else {
                                            return (product.category === this.state.active);
                                        }
                                    }).map((product, i) => (
                                        <Product 
                                            asin={product.asin}
                                            imageUrl={product.imageUrl}
                                            category={product.category}
                                            linkUrl={product.linkUrl}
                                            title={product.title}
                                            description={product.description}
                                            supporter={product.supporter}
                                            supporterImage={product.supporterImage}
                                            added={product.added} />
                                        )
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default Products;