import React, { Component } from 'react'

class Pricing extends Component {
    static displayName = 'Pricing';

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div className="bg-gray-900" id="pricing">
                <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
                    <div className="text-center">
                        <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                            Pricing
                        </h2>
                        <p className="mt- text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
                            Positive Dog Training with Real Results
                        </p>
                        <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-5 sm:text-2xl">
                            In-home one-on-one personalized training session with a professional animal trainer.
                            We work with all breeds of dogs, please contact us with any requests or questions. 
                        </p>
                    </div>
                </div>

                <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
                    <div className="relative z-0">
                        <div className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3"></div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="relative lg:grid lg:grid-cols-7">
                                <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                                    <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                                        <div className="flex-1 flex flex-col">
                                            <div className="bg-white px-6 py-10">
                                                <div>
                                                    <h3 className="text-center text-2xl font-medium text-gray-900" id="tier-hobby">
                                                        Refresher
                                                    </h3>
                                                    <div className="mt-4 flex items-center justify-center">
                                                        <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900">
                                                            <span className="mt-2 mr-2 text-4xl font-medium">
                                                                $
                                                            </span>
                                                            <span className="font-extrabold">
                                                                400
                                                            </span>
                                                        </span>
                                                        {/*<span className="text-xl font-medium text-gray-500">
                                                            /month
                                                        </span>*/}
                                                    </div>
                                                    <h4 className="text-center text-md font-medium text-gray-600">
                                                        3 One Hour Sessions
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                                                <ul className="space-y-4">
                                                    <li className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                            </svg>
                                                        </div>
                                                        <p className="ml-3 text-base font-medium text-gray-500">
                                                            Previous Training Experience
                                                        </p>
                                                    </li>

                                                    <li className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                            </svg>
                                                        </div>
                                                        <p className="ml-3 text-base font-medium text-gray-500">
                                                            Needs Help with Forgotten Behaviors
                                                        </p>
                                                    </li>

                                                    <li className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                            </svg>
                                                        </div>
                                                        <p className="ml-3 text-base font-medium text-gray-500">
                                                            Refresh Advanced Behaviors
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div className="mt-8">
                                                    <div className="rounded-lg shadow-md">
                                                        <a href="/#get-started" className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-green-600 hover:bg-gray-50" aria-describedby="tier-hobby">
                                                            Schedule Session
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                                    <div className="relative z-10 rounded-lg shadow-xl">
                                        <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-green-600" aria-hidden="true"></div>
                                        <div className="absolute inset-x-0 top-0 transform translate-y-px">
                                            <div className="flex justify-center transform -translate-y-1/2">
                                                <span className="inline-flex rounded-full bg-green-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">
                                                    Most popular
                                                </span>
                                            </div>
                                        </div>
                                        <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                                            <div>
                                                <h3 className="text-center text-3xl font-semibold text-gray-900 sm:-mx-6" id="tier-growth">
                                                    Basics
                                                </h3>
                                                <div className="mt-4 flex items-center justify-center">
                                                    <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900 sm:text-6xl">
                                                        <span className="mt-2 mr-2 text-4xl font-medium">
                                                            $
                                                        </span>
                                                        <span className="font-extrabold">
                                                            600
                                                        </span>
                                                    </span>
                                                    {/*<span className="text-2xl font-medium text-gray-500">
                                                        /month
                                                    </span>*/}
                                                </div>
                                                <h4 className="text-center text-md font-medium text-gray-600">
                                                    5 One Hour Sessions
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                                            <ul className="space-y-4">
                                                <li className="flex items-start">
                                                    <div className="flex-shrink-0">
                                                        <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </div>
                                                    <p className="ml-3 text-base font-medium text-gray-500">
                                                        New Puppy Training
                                                    </p>
                                                </li>

                                                <li className="flex items-start">
                                                    <div className="flex-shrink-0">
                                                        <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </div>
                                                    <p className="ml-3 text-base font-medium text-gray-500">
                                                        Obedience Basics
                                                    </p>
                                                </li>

                                                <li className="flex items-start">
                                                    <div className="flex-shrink-0">
                                                        <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </div>
                                                    <p className="ml-3 text-base font-medium text-gray-500">
                                                        Recall
                                                    </p>
                                                </li>

                                                <li className="flex items-start">
                                                    <div className="flex-shrink-0">
                                                        <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </div>
                                                    <p className="ml-3 text-base font-medium text-gray-500">
                                                        Sit / Stay / Come
                                                    </p>
                                                </li>

                                                <li className="flex items-start">
                                                    <div className="flex-shrink-0">
                                                        <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </div>
                                                    <p className="ml-3 text-base font-medium text-gray-500">
                                                        Beginning Leash Walking
                                                    </p>
                                                </li>

                                                <li className="flex items-start">
                                                    <div className="flex-shrink-0">
                                                        <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </div>
                                                    <p className="ml-3 text-base font-medium text-gray-500">
                                                        Customized Training Plan
                                                    </p>
                                                </li>

                                            </ul>
                                            <div className="mt-10">
                                                <div className="rounded-lg shadow-md">
                                                    <a href="/#get-started" className="block w-full text-center rounded-lg border border-transparent bg-green-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-green-700" aria-describedby="tier-growth">
                                                        Schedule Session
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                                    <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                                        <div className="flex-1 flex flex-col">
                                            <div className="bg-white px-6 py-10">
                                                <div>
                                                    <h3 className="text-center text-2xl font-medium text-gray-900" id="tier-scale">
                                                        Basics+
                                                    </h3>
                                                    <div className="mt-4 flex items-center justify-center">
                                                        <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900">
                                                            <span className="mt-2 mr-2 text-4xl font-medium">
                                                                $
                                                            </span>
                                                            <span className="font-extrabold">
                                                                1100
                                                            </span>
                                                        </span>
                                                        {/*<span className="text-xl font-medium text-gray-500">
                                                            /month
                                                        </span>*/}
                                                    </div>
                                                    <h4 className="text-center text-md font-medium text-gray-600">
                                                        10 One Hour Sessions
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                                                <ul className="space-y-4">
                                                    <li className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                            </svg>
                                                        </div>
                                                        <p className="ml-3 text-base font-medium text-gray-500">
                                                            All Features of the Basic Plan
                                                        </p>
                                                    </li>

                                                    <li className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                            </svg>
                                                        </div>
                                                        <p className="ml-3 text-base font-medium text-gray-500">
                                                            Good for Multiple Dogs
                                                        </p>
                                                    </li>

                                                    <li className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                            </svg>
                                                        </div>
                                                        <p className="ml-3 text-base font-medium text-gray-500">
                                                            Advanced Behaviors
                                                        </p>
                                                    </li>

                                                    <li className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                            </svg>
                                                        </div>
                                                        <p className="ml-3 text-base font-medium text-gray-500">
                                                            Advanced Reactivity / Leash Training
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div className="mt-8">
                                                    <div className="rounded-lg shadow-md">
                                                        <a href="/#get-started" className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-green-600 hover:bg-gray-50" aria-describedby="tier-scale">
                                                            Schedule Session
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pricing;