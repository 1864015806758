import React, { Component } from 'react'
import Header from './Header'
import ContactWizard from './ContactWizard'
import AboutUs from './AboutUs'
import Pricing from './Pricing'
import Footer from './Footer'
import Modal from './components/Modal'

class Home extends Component {
    static displayName = 'Home';

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div className="App">
                <Modal />
                <Header showHero={true} />
                <Pricing />
                <AboutUs />
                <ContactWizard />
                <Footer />
            </div>
        );
    }
}

export default Home;