import React, { useState, useEffect } from 'react'

export default function ContactForm() {
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if ( window.location.search.includes('success=true') ) {
          setSuccess(true);
        }
    }, []);

    return (
        <div className="bg-white py-4 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-8 rounded-2xl shadow-lg">
            <div className="relative max-w-xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Start Training Today
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-gray-500">
                        Let us know a little about your pup and what you'd like to accomplish. We'll contact you to setup a session.
                    </p>
                </div>
                <div className="mt-12">
                    {success && (
                        <p className="p-2 rounded shadow bg-green-500 text-white mb-2">Thanks for your message! We'll get back to you soon.</p>
                    )}
                    <form action="/?success=true" method="POST" data-netlify="true" className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                        <input type="hidden" name="form-name" value="contact" />
                        <div className="sm:col-span-2">
                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Name</label>
                            <div className="mt-1">
                                <input type="text" name="name" id="name" autoComplete="name" className="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md" placeholder="Jane Smith" />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="zip" className="block text-sm font-medium text-gray-700">Zip Code</label>
                            <div className="mt-1">
                                <input type="text" id="zip" name="zip" autoComplete="zip" placeholder="85205" className="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md" />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                            <div className="mt-1">
                                <input id="email" name="email" type="email" autoComplete="email" className="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md" placeholder="example@mail.com" />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone Number</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input type="tel" name="phone" id="phone" autoComplete="tel" className="py-3 px-4 block w-full focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md" placeholder="480-111-1111" />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="marketing" className="block text-sm font-medium text-gray-700">How did you hear about us? (optional)</label>
                            <select id="marketing" name="marketing" class="mt-1 block w-full pl-3 pr-10 py-3 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md shadow-sm">
                                <option selected>-- Select --</option>
                                <option value="Nerd Puppy Vehicle Advertisement">Nerd Puppy Vehicle Advertisement</option>
                                <option value="Word of Mouth">Word of Mouth</option>
                                <option value="Google Search">Google Search</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Yelp">Yelp</option>
                                <option value="Business Card">Business Card</option>
                                <option value="Veterinarian">Veterinarian</option>
                                <option value="Pet Store">Pet Store</option>
                            </select>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                            <span className="text-xs text-gray-400">Dog Breed, Issues to Work on, Questions etc.</span>
                            <div className="mt-1">
                                <textarea id="message" name="message" rows="4" className="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"></textarea>
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <button onClick={window.gtag('event', 'click', {'event_category': 'Click','event_label': "Contact Form Submit"})} type="submit" className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                Let's talk
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}