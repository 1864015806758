import React, { Component } from 'react'
import Header from './Header'
import Dropdown from './components/Dropdown'
import Footer from './Footer'

class Faq extends Component {
    static displayName = 'Faq';

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div className="App">
                <Header showHero={false} />
                <div className="bg-gray-50">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                            <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
                                Frequently asked questions
                            </h2>
                            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                                <Dropdown question={"What cities do you cover?"} answer={"We operate in Gilbert, Chandler and Mesa. We currently don't service the West Valley. Contact us for more information."} />
                                <Dropdown question={"Is there an additional charge for multiple dogs?"} answer={"No, there is no additional charge. However depending on your training goals, it may require extra sessions in order to give enough attention to each dog."} />
                                <Dropdown question={"What if my dog already knows sit?"} answer={"Each package is tailored to your needs. So if your pup already knows certain behaviors then time can be allotted for more advanced behaviors."} />
                                <Dropdown question={"What if my dog isn't food motivated?"} answer={"Just like people, each dog is an individual and we'll work with you to figure out what is the best type of reinforcement for your pup. (play, tennis ball, tug toy etc.)"} />
                                <Dropdown question={"Can my children attend the session?"} answer={"Yes, all family members are welcome and encouraged to attend the sessions."} />
                                <Dropdown question={"What if I need to reschedule a session?"} answer={"You may reschedule any session without a fee if you do so at least 24 hours in advance. If you do not give 24 hours notice, you may be subject to a cancellation fee of $30. Multiple cancellations will lead to the forfeit of a session."} />
                                <Dropdown question={"Do you offer payment plans?"} answer={"Yes, upon request we can offer a payment plan of two payments. First payment due at the first session and the second payment due at the halfway point."} />
                                <Dropdown question={"Do you have a refund policy?"} answer={"No, we do not offer refunds. If you have extenuating circumstances, please speak with your trainer."} />
                            </dl>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default Faq;